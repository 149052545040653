import { acquireAccessToken } from '@wavetronix/common-components'
import { Buffer } from 'buffer'
import { env } from '../index.js'
import axios from 'axios'

let instance = null

class UsersApi {
  getUsers = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getMe = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getUser = async id => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.gatekeeperUserName}:${env.basicAuthentication.gatekeeperPassword}`
    ).toString('base64')
    return axios
      .get(`${env.urls.gatekeeperURL}/users/email/${id}`, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(res => {
        return res.data
      })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new UsersApi()
  }
  return instance
}

export default getInstance()
