import { CenteredDiv, WtxColors } from '@wavetronix/common-components'
import WtxLogo from './CertificateAssets/logo'
import seal from './CertificateAssets/seal.png'
import { useEffect } from 'react'

export default function CertificateTemplate({ setLoaded, htmlRef, certificateInfo, ...props }) {
  useEffect(() => {
    setLoaded(true)
  }, [htmlRef, setLoaded])

  return (
    <div ref={htmlRef}>
      <CenteredDiv>
        <div
          style={{
            width: '1000px',
            border: '3px solid black',
            padding: '2px'
          }}
        >
          <div
            style={{
              border: '1px solid black',
              padding: '48px',
              fontFamily: 'Klavika'
            }}
          >
            <div style={{ textAlign: 'center', fontSize: '24pt' }}>
              <h3 style={{ margin: 0, color: WtxColors.ASPHALT }}>Wavetronix Continuing Education Program</h3>
              <div
                style={{
                  width: '100%',
                  height: '3px',
                  backgroundColor: WtxColors.GOLD
                }}
              ></div>
              <h2 style={{ margin: 0 }}>Certificate of Completion</h2>
            </div>
            <CenteredDiv>
              <div
                style={{
                  display: 'block',
                  textAlign: 'center',
                  margin: '32px'
                }}
              >
                <p
                  style={{
                    margin: '4px',
                    fontSize: '14pt',
                    fontFamily: 'serif'
                  }}
                >
                  This certificate is awarded to
                </p>
                <h1 style={{ margin: '8px' }}>{`${certificateInfo.user}`}</h1>
                <p
                  style={{
                    margin: '4px',
                    fontSize: '14pt',
                    fontFamily: 'serif'
                  }}
                >{`to certify that all training requirements`}</p>
                <p
                  style={{
                    margin: '4px',
                    fontSize: '14pt',
                    fontFamily: 'serif'
                  }}
                >{`have been successfully completed for`}</p>
                <h2 style={{ margin: '16px' }}>{`${certificateInfo.exam}`}</h2>
              </div>
            </CenteredDiv>
            <CenteredDiv>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                {/* <WtxSeal style={{ height: '200px', width: '240px' }}></WtxSeal> */}
                <div
                  style={{
                    width: '200px',
                    height: '200px',
                    backgroundImage: `url(${seal})`,
                    backgroundSize: 'contain'
                  }}
                ></div>
                <p>{`Certificate issued by: ${certificateInfo.issuer}`}</p>
                <div style={{ textAlign: 'center' }}>
                  <p>{`Effective date: ${certificateInfo.issueDate}`}</p>
                  <p>{`Expires on: ${certificateInfo.expireDate}`}</p>
                  <WtxLogo style={{ width: '240px' }}></WtxLogo>
                </div>
              </div>
            </CenteredDiv>
          </div>
        </div>
      </CenteredDiv>
    </div>
  )
}
